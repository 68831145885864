import * as React from "react";
import { SemanticICONS, Icon, Popup, Button } from "semantic-ui-react";

interface LinkIconProps extends React.HTMLProps<HTMLDivElement> {
  url: string;
  icon: SemanticICONS;
}

const style = {
  borderRadius: 0,
  opacity: 0.9,
  padding: "1em",
  background: "#282c34",
  color: "white",
  fontWeight: "bold"
};

const LinkIcon = ({ url, icon }: LinkIconProps) => {
  return (
    <Popup
      on="hover"
      content={url}
      style={style}
      position="bottom left"
      trigger={
        <Button as="a" href={url} circular icon={<Icon name={icon} />}></Button>
      }
    />
  );
};

export default LinkIcon;
